import Vue from 'vue';
import Router from 'vue-router';
import { normalizeURL, decode } from 'ufo';
import { interopDefault } from './utils';
import scrollBehavior from './router.scrollBehavior.js';
const _36c5844c = () => interopDefault(import('../pages/agreement-accepted.vue' /* webpackChunkName: "pages/agreement-accepted" */));
const _7e713df6 = () => interopDefault(import('../pages/app-link.vue' /* webpackChunkName: "pages/app-link" */));
const _b20bc810 = () => interopDefault(import('../pages/app/link.vue' /* webpackChunkName: "pages/app/link" */));
const _27018aa0 = () => interopDefault(import('../pages/application.vue' /* webpackChunkName: "pages/application" */));
const _e1a5a6f6 = () => interopDefault(import('../pages/AV.vue' /* webpackChunkName: "pages/AV" */));
const _18d5a3a5 = () => interopDefault(import('../pages/banklookupiframe_.vue' /* webpackChunkName: "pages/banklookupiframe_" */));
const _2866c699 = () => interopDefault(import('../pages/comms-settings.vue' /* webpackChunkName: "pages/comms-settings" */));
const _bdc9d500 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */));
const _7ad78158 = () => interopDefault(import('../pages/download.vue' /* webpackChunkName: "pages/download" */));
const _fdd5e202 = () => interopDefault(import('../pages/friendpass.vue' /* webpackChunkName: "pages/friendpass" */));
const _11c27092 = () => interopDefault(import('../pages/giveaways/index.vue' /* webpackChunkName: "pages/giveaways/index" */));
const _d276bd48 = () => interopDefault(import('../pages/legal/index.vue' /* webpackChunkName: "pages/legal/index" */));
const _57062649 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */));
const _3eadfdbc = () => interopDefault(import('../pages/members/index.vue' /* webpackChunkName: "pages/members/index" */));
const _5a8931eb = () => interopDefault(import('../pages/password.vue' /* webpackChunkName: "pages/password" */));
const _f5a53008 = () => interopDefault(import('../pages/app/dismiss.vue' /* webpackChunkName: "pages/app/dismiss" */));
const _159d8ac4 = () => interopDefault(import('../pages/auth/user-token.vue' /* webpackChunkName: "pages/auth/user-token" */));
const _7691d2cc = () => interopDefault(import('../pages/auth/user-token-sync.vue' /* webpackChunkName: "pages/auth/user-token-sync" */));
const _32eab9c2 = () => interopDefault(import('../pages/legal/cardholder-agreement.vue' /* webpackChunkName: "pages/legal/cardholder-agreement" */));
const _409e5c92 = () => interopDefault(import('../pages/legal/privacy.vue' /* webpackChunkName: "pages/legal/privacy" */));
const _b252fa18 = () => interopDefault(import('../pages/legal/state-licenses.vue' /* webpackChunkName: "pages/legal/state-licenses" */));
const _4a99e4a2 = () => interopDefault(import('../pages/members/agreements.vue' /* webpackChunkName: "pages/members/agreements" */));
const _2b82cfd4 = () => interopDefault(import('../pages/members/card.vue' /* webpackChunkName: "pages/members/card" */));
const _236eaef8 = () => interopDefault(import('../pages/members/cau/index.vue' /* webpackChunkName: "pages/members/cau/index" */));
const _0855a0d3 = () => interopDefault(import('../pages/members/login.vue' /* webpackChunkName: "pages/members/login" */));
const _4f6f8210 = () => interopDefault(import('../pages/members/logout.vue' /* webpackChunkName: "pages/members/logout" */));
const _408f4e3a = () => interopDefault(import('../pages/members/oloc/index.vue' /* webpackChunkName: "pages/members/oloc/index" */));
const _20b61b16 = () => interopDefault(import('../pages/members/persona.vue' /* webpackChunkName: "pages/members/persona" */));
const _89cfce60 = () => interopDefault(import('../pages/members/scheduled-payment.vue' /* webpackChunkName: "pages/members/scheduled-payment" */));
const _0bca19a8 = () => interopDefault(import('../pages/oauth/plaid.vue' /* webpackChunkName: "pages/oauth/plaid" */));
const _8c8b1308 = () => interopDefault(import('../pages/oauth/plaid-app.vue' /* webpackChunkName: "pages/oauth/plaid-app" */));
const _2b8400d6 = () => interopDefault(import('../pages/shopify/create-account.vue' /* webpackChunkName: "pages/shopify/create-account" */));
const _b1d8dfe4 = () => interopDefault(import('../pages/users/switch-bank.vue' /* webpackChunkName: "pages/users/switch-bank" */));
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */));
const _0210f5b8 = () => interopDefault(import('../pages/content/_page.vue' /* webpackChunkName: "pages/content/_page" */));
const _2054f040 = () => interopDefault(import('../pages/giveaways/_giveaways.vue' /* webpackChunkName: "pages/giveaways/_giveaways" */));
const _b7090456 = () => interopDefault(import('../pages/survey/_id.vue' /* webpackChunkName: "pages/survey/_id" */));
const _7b400e89 = () => interopDefault(import('../pages/sweepstake/_id.vue' /* webpackChunkName: "pages/sweepstake/_id" */));
const _272fde66 = () => interopDefault(import('../pages/_sanity.vue' /* webpackChunkName: "pages/_sanity" */));
const _2563ddd0 = () => interopDefault(import('~/pages/index.vue' /* webpackChunkName: "" */));
const emptyFn = () => {};
Vue.use(Router);
export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,
  routes: [{
    path: "/agreement-accepted",
    component: _36c5844c,
    name: "agreement-accepted"
  }, {
    path: "/app-link",
    component: _7e713df6,
    children: [{
      path: "",
      component: _b20bc810,
      name: "app-link"
    }]
  }, {
    path: "/application",
    component: _27018aa0,
    name: "application"
  }, {
    path: "/AV",
    component: _e1a5a6f6,
    name: "AV"
  }, {
    path: "/banklookupiframe_",
    component: _18d5a3a5,
    name: "banklookupiframe_"
  }, {
    path: "/comms-settings",
    component: _2866c699,
    name: "comms-settings"
  }, {
    path: "/contact",
    component: _bdc9d500,
    name: "contact"
  }, {
    path: "/download",
    component: _7ad78158,
    name: "download"
  }, {
    path: "/friendpass",
    component: _fdd5e202,
    name: "friendpass"
  }, {
    path: "/giveaways",
    component: _11c27092,
    name: "giveaways"
  }, {
    path: "/legal",
    component: _d276bd48,
    name: "legal"
  }, {
    path: "/login",
    component: _57062649,
    name: "login"
  }, {
    path: "/members",
    component: _3eadfdbc,
    name: "members"
  }, {
    path: "/password",
    component: _5a8931eb,
    name: "password"
  }, {
    path: "/app/dismiss",
    component: _f5a53008,
    name: "app-dismiss"
  }, {
    path: "/auth/user-token",
    component: _159d8ac4,
    name: "auth-user-token"
  }, {
    path: "/auth/user-token-sync",
    component: _7691d2cc,
    name: "auth-user-token-sync"
  }, {
    path: "/legal/cardholder-agreement",
    component: _32eab9c2,
    name: "legal-cardholder-agreement"
  }, {
    path: "/legal/privacy",
    component: _409e5c92,
    name: "legal-privacy"
  }, {
    path: "/legal/state-licenses",
    component: _b252fa18,
    name: "legal-state-licenses"
  }, {
    path: "/members/agreements",
    component: _4a99e4a2,
    name: "members-agreements"
  }, {
    path: "/members/card",
    component: _2b82cfd4,
    name: "members-card"
  }, {
    path: "/members/cau",
    component: _236eaef8,
    name: "members-cau"
  }, {
    path: "/members/login",
    component: _0855a0d3,
    name: "members-login"
  }, {
    path: "/members/logout",
    component: _4f6f8210,
    name: "members-logout"
  }, {
    path: "/members/oloc",
    component: _408f4e3a,
    name: "members-oloc"
  }, {
    path: "/members/persona",
    component: _20b61b16,
    name: "members-persona"
  }, {
    path: "/members/scheduled-payment",
    component: _89cfce60,
    name: "members-scheduled-payment"
  }, {
    path: "/oauth/plaid",
    component: _0bca19a8,
    name: "oauth-plaid"
  }, {
    path: "/oauth/plaid-app",
    component: _8c8b1308,
    name: "oauth-plaid-app"
  }, {
    path: "/shopify/create-account",
    component: _2b8400d6,
    name: "shopify-create-account"
  }, {
    path: "/users/switch-bank",
    component: _b1d8dfe4,
    name: "users-switch-bank"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/content/:page?",
    component: _0210f5b8,
    name: "content-page"
  }, {
    path: "/giveaways/:giveaways",
    component: _2054f040,
    name: "giveaways-giveaways"
  }, {
    path: "/survey/:id?",
    component: _b7090456,
    name: "survey-id"
  }, {
    path: "/sweepstake/:id?",
    component: _7b400e89,
    name: "sweepstake-id"
  }, {
    path: "/:sanity",
    component: _272fde66,
    name: "sanity"
  }, {
    path: "/_/betalander",
    component: _2563ddd0,
    name: "betalander"
  }],
  fallback: false
};
export function createRouter(ssrContext, config) {
  const base = config._app && config._app.basePath || routerOptions.base;
  const router = new Router({
    ...routerOptions,
    base
  });

  // TODO: remove in Nuxt 3
  const originalPush = router.push;
  router.push = function push(location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort);
  };
  const resolve = router.resolve.bind(router);
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to);
    }
    return resolve(to, current, append);
  };
  return router;
}