var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('footer', [!_vm.showOnlyDisclosure ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "group company"
  }, [_c('a', {
    attrs: {
      "href": "/"
    }
  }, [_vm.showBlackLogo ? _c('img', {
    staticClass: "logo",
    attrs: {
      "src": require("assets/images/extra-logo-black.svg"),
      "alt": "Extra logo"
    }
  }) : _c('img', {
    staticClass: "logo",
    attrs: {
      "src": require("assets/images/extra-logo-white.svg"),
      "alt": "Extra logo"
    }
  })]), _vm._v(" "), _c('p', {
    staticClass: "copyright"
  }, [_vm._v(" © " + _vm._s(_vm.year) + " The Aligned Company "), _c('br'), _vm._v(" d./b./a. Extra"), _c('br'), _vm._v(" NMLS #2341556 ")]), _vm._v(" "), _vm._m(0)]), _vm._v(" "), _vm._m(1), _vm._v(" "), _c('div', {
    staticClass: "link-group"
  }, [_c('h4', [_vm._v("Product")]), _vm._v(" "), _c('ul', {
    staticClass: "links"
  }, [_c('li', {
    staticClass: "link"
  }, [_c('a', {
    on: {
      "click": () => _vm.onSignupClick('footer', 'apply')
    }
  }, [_vm._v("Get Started")])]), _vm._v(" "), _vm._m(2), _vm._v(" "), _c('li', {
    staticClass: "link"
  }, [_c('nuxt-link', {
    attrs: {
      "to": "/download"
    }
  }, [_vm._v("Download")])], 1), _vm._v(" "), _c('li', {
    staticClass: "link"
  }, [_c('nuxt-link', {
    attrs: {
      "to": "/members"
    }
  }, [_vm._v("Members Area")])], 1), _vm._v(" "), _vm._m(3)])]), _vm._v(" "), _c('div', {
    staticClass: "link-group"
  }, [_c('h4', [_vm._v("Company")]), _vm._v(" "), _c('ul', {
    staticClass: "links"
  }, [_c('li', {
    staticClass: "link"
  }, [_c('nuxt-link', {
    attrs: {
      "to": "/contact"
    }
  }, [_vm._v("Contact Us")])], 1), _vm._v(" "), _vm._m(4)])]), _vm._v(" "), _vm._m(5), _vm._v(" "), _vm._m(6)]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "disclosure"
  }, [_c('p', [_c('sup', [_vm._v("1")]), _vm._v("The Aligned Company d/b/a Extra (“Extra”) is a technology company, not a bank. The Extra Debit Card\n\t\t\tis issued by Evolve Bank & Trust or Patriot Bank N.A. (Member FDIC), pursuant to a license by Mastercard\n\t\t\tInternational. Line of credit provided by Lead Bank. This Card can be used everywhere Debit Mastercard is\n\t\t\taccepted. Extra is responsible for credit reporting and reports on time and late payments, which may impact a\n\t\t\tcredit bureau’s determination of your credit score. Credit scores are independently determined by credit bureaus\n\t\t\tbased on a number of factors, including your other financial transactions. Extra reports to Experian® and\n\t\t\tEquifax®. The Extra Debit Card and related services will not remove negative credit history from your credit\n\t\t\treport. Rewards points only available with rewards plan. Membership fees apply. Please refer to our "), _c('a', {
    staticClass: "underline",
    attrs: {
      "href": "/legal/terms"
    }
  }, [_vm._v("Terms of Service")]), _vm._v(" and "), _c('a', {
    staticClass: "underline",
    attrs: {
      "href": "/legal/cardholder-agreement"
    }
  }, [_vm._v("Cardholder Agreement")]), _vm._v(" for additional details. "), _c('br'), _vm._v(" "), _c('br'), _vm._v(" "), _c('sup', [_vm._v("2")]), _vm._v("To transact with your Extra Debit Card, you must have an active, Plaid-compatible bank account\n\t\t\tlinked to Extra’s services (“Partner Account”). If your Partner Account is disconnected from Extra services for\n\t\t\tany reason, you will not be able to use your Extra Debit Card. Within 30 days of the date of each transaction\n\t\t\tmade using your Extra Debit Card, Extra will initiate one or more ACH debits to deduct equivalent funds from\n\t\t\tyour Partner Account. These ACH debits typically deduct funds from your Partner Account within 2 to 3 business\n\t\t\tdays after initiation by Extra. "), _c('br'), _vm._v(" "), _c('br'), _vm._v(" "), _c('sup', [_vm._v("3")]), _vm._v("Data based on a study of active Extra customers with good credit habits. Individual results may\n\t\t\tvary. To learn more, please see the full study at "), _c('a', {
    staticClass: "underline",
    attrs: {
      "href": "/study"
    }
  }, [_vm._v("extra.app/study")]), _vm._v(". "), _c('br'), _vm._v(" "), _c('br'), _vm._v(" THIS IS A LOAN SOLICITATION ONLY. THE ALIGNED COMPANY IS NOT THE LENDER. INFORMATION RECEIVED WILL BE\n\t\t\tSHARED WITH ONE OR MORE THIRD PARTIES IN CONNECTION WITH YOUR LOAN INQUIRY. THE LENDER MAY NOT BE SUBJECT TO ALL\n\t\t\tVERMONT LENDING LAWS. THE LENDER MAY BE SUBJECT TO FEDERAL LENDING LAWS. "), _vm.showOnlyDisclosure ? [_c('br'), _vm._v(" "), _c('br'), _vm._v(" NMLS #2341556 ")] : _vm._e()], 2)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "mt-4"
  }, [_vm._v("Contact Us: "), _c('a', {
    attrs: {
      "href": "mailto:hey@extra.app"
    }
  }, [_vm._v("hey@extra.app")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "divider"
  }, [_c('div', {
    staticClass: "fill"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', {
    staticClass: "link"
  }, [_c('a', {
    attrs: {
      "href": "/blog"
    }
  }, [_vm._v("Blog")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', {
    staticClass: "link"
  }, [_c('a', {
    attrs: {
      "href": "/help"
    }
  }, [_vm._v("Support Center")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', {
    staticClass: "link"
  }, [_c('a', {
    attrs: {
      "href": "https://boards.greenhouse.io/extra",
      "target": "blank"
    }
  }, [_vm._v("Careers")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "link-group"
  }, [_c('h4', [_vm._v("Resources")]), _vm._v(" "), _c('ul', {
    staticClass: "links"
  }, [_c('li', {
    staticClass: "link"
  }, [_c('a', {
    attrs: {
      "href": "/legal/privacy"
    }
  }, [_vm._v("Privacy Policy")])]), _vm._v(" "), _c('li', {
    staticClass: "link"
  }, [_c('a', {
    attrs: {
      "href": "/legal/terms"
    }
  }, [_vm._v("Terms of Service")])]), _vm._v(" "), _c('li', {
    staticClass: "link"
  }, [_c('a', {
    attrs: {
      "href": "/legal"
    }
  }, [_vm._v("Legal Documents")])]), _vm._v(" "), _c('li', {
    staticClass: "link"
  }, [_c('a', {
    attrs: {
      "href": "/legal/state-licenses"
    }
  }, [_vm._v("State Licenses")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "link-group"
  }, [_c('h4', [_vm._v("Follow")]), _vm._v(" "), _c('ul', {
    staticClass: "links"
  }, [_c('li', {
    staticClass: "link"
  }, [_c('a', {
    attrs: {
      "href": "https://www.instagram.com/extra.card/"
    }
  }, [_vm._v("Instagram")])]), _vm._v(" "), _c('li', {
    staticClass: "link"
  }, [_c('a', {
    attrs: {
      "href": "https://twitter.com/extracard"
    }
  }, [_vm._v("Twitter")])]), _vm._v(" "), _c('li', {
    staticClass: "link"
  }, [_c('a', {
    attrs: {
      "href": "https://www.tiktok.com/@extra"
    }
  }, [_vm._v("TikTok")])])])]);
}];
export { render, staticRenderFns };